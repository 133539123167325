<template>
  <div>
    <div v-if="isCancelado" class="bg-red-400 justify-evenly border cancelado content-center rounded-b-md p-2 flex flex-col lg:flex-row m-3">
      <div class="flex flex-col lg:flex-row">
        <span class="text-base text-black ml-2">
          Esta venda foi cancelada no dia
        </span>
        <span class="text-base text-black font-bold">
          {{ motivosCancelamento.data }}</span>
        <span class="text-base text-black mr-2"> pelo seguinte motivo: </span>
        <span class="text-base text-black font-bold">
          {{ motivosCancelamento.motivo }}
        </span>
      </div>

      <div class="flex ml-10">
        <button type="button" class="btn w-56 bg-yellow-400 rounded-md flex items-center content-center justify-center"
          @click="trocarMotivo()">
          Trocar Motivo Cancelamento
        </button>
      </div>
    </div>

    <div class="my-8 flex flex-col lg:flex-row items-center justify-between">
      <div>
        <h3 class="font-bold">
          Editar venda > <span class="text-primary">{{ venda.transacao }}</span>
        </h3>

        <span class="text-sm text-gray-500">Verifique as informações abaixo e clique no botão Salvar para
          confirmar as modificações feitas.
        </span>
      </div>
      <div class="flex justify-between gap-4 mt-3">
        <Button @click="openModalEmail()" color="primary" text="Reenviar venda Email" />

        <Button @click="reenviarFirebase()" color="primary" text="Reenviar venda Firebase" />
      </div>
    </div>

    <div class="card shadow-sm border p-8 bg-white">
      <div class="w-full">
        <div class="flex-3 w-full mb-7">
          <h3 class="font-bold">Informações da Venda</h3>
          <span class="text-sm text-gray-500">
            Aqui você pode observar todos os dados relacionados a venda.
          </span>
        </div>
      </div>

      <div class="flex flex-col lg:flex-row">
        <div class="w-full m-2">
          <Input type="default" v-model="venda.nome" label="Nome" placeholder="Nome" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="cpf" v-model="venda.cpf" label="CPF" placeholder="CPF" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.telefone" label="Telefone" placeholder="Telefone" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.email" label="Email" placeholder="Email" :readonly="true" />
        </div>
      </div>
      <div class="flex flex-col lg:flex-row">
        <div class="w-full m-2">
          <Input type="default" v-model="venda.endereco" label="Endereço" placeholder="Endereço" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.cep" label="Cep" placeholder="Cep" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.bairro" label="Bairro" placeholder="Bairro" :readonly="true" />
        </div>
      </div>
      <div class="flex flex-col lg:flex-row">
        <div class="w-full m-2">
          <Input type="default" v-model="venda.cidade" label="Cidade" placeholder="Cidade" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.estado" label="Estado" placeholder="Estado" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.complemento" label="Complemento" placeholder="Complemento"
            :readonly="true" />
        </div>
      </div>
      <div class="flex flex-col lg:flex-row">
        <div class="w-full m-2">
          <Input type="default" v-model="venda.pedido" label="Pedido" placeholder="Pedido" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.transacao" label="Transacao" placeholder="Transaço" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.data2" label="Data da Venda" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="money" v-model="venda.valor_loja" label="Valor Loja" :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="money" v-model="venda.valor_original" label="Valor Original" :readonly="true" />
        </div>
      </div>

      <div class="flex flex-col lg:flex-row">
        <div class="w-full m-2">
          <Input type="default" v-model="venda.meio_pagamento" label="Meio de Pagamento" placeholder="Meio de Pagamento"
            :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.parcelas" label="Parcelas da compra" placeholder="Número de parcelas"
            :readonly="true" />
        </div>
        <div class="w-full m-2">
          <Input type="default" v-model="venda.data_aprovacao2" label="venda de Aprovação" :readonly="true" />
        </div>
      </div>
    </div>

    <div class="card shadow-sm border p-8 bg-white my-3">
      <div class="w-full">
        <div class="flex-3 w-full mb-7">
          <h3 class="font-bold">Informações de produtos</h3>
          <span class="text-sm text-gray-500">
            Aqui você pode observar todos os dados relacionados aos produtos
            dessa venda.
          </span>
        </div>
        <div class="flex justify-end" v-if="!isCancelado">
          <Button @click="cancel" color="primary" text="Cancelar Venda Integral" />
        </div>
      </div>

      <div class="card">
        <DataTable :headers="headers" :data="detalhes" :options="options" color="primary" />
      </div>
    </div>
    <modalQrcode :show="showModal" :qrcode="QRCodes" :method="closeShow" />
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import DataTable from "../../components/Elements/Datatable.vue";
import modalQrcode from "../../components/Elements/ModalQcode.vue"
import { GET, PUT, DELETE, POST_VENDAS } from "../../services/api";
import { toDate, toMoney } from "../../services/formater";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "../../services/validate";

import store from "../../../store/index";


export default {
  name: "EditarVenda",
  components: {
    Input,
    Button,
    DataTable,
    modalQrcode
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal");
    const modalconfirmation = inject("modalconfirmation");
    const modalTrocaMotivo = inject("modaltrocamotivo");
    const modalEmail = inject("modalEmail");

    const venda = ref({});
    const detalhes = ref([]);
    const profiles = ref([]);
    const preview = ref(null);
    const isCancelado = ref(false);
    const showModal = ref(false);
    const motivosCancelamento = ref("");

    const eventos = ref([]);
    const tipos = ref([]);
    const subtipos = ref([]);
    const QRCodes = ref([]);

    onMounted(async () => {
      loader.open();
      venda.value = { ...(await GET(`vendas/${route.params.id}`)) };

      //edição dos valores de data e valor
      if (venda.value["data"][10] == "T") {
        venda.value["data2"] = toDate(venda.value.data);
      }
      if (
        venda.value["data_aprovacao"] != null &&
        venda.value["data_aprovacao"][10] == "T"
      ) {
        venda.value["data_aprovacao2"] = toDate(venda.value["data_aprovacao"]);
      }

      venda.value["valor_original"] = toMoney(venda.value["valor_original"]);
      venda.value["valor_loja"] = toMoney(venda.value["valor_loja"]);

      //detalhes da venda (produtos):

      const aux = await GET(`/vendas/${route.params.id}/detalhes`);

      detalhes.value = aux.map((elem) => {
        elem.identifica = venda.value.pedido + "/" + elem.identificador;

        return elem;
      });



      if (venda.value.status_pedido == 0) {
        isCancelado.value = true;

        try {
          motivosCancelamento.value = await GET(
            `venda_cancelamento/${route.params.id}/venda`
          );
        } catch (error) {
          motivosCancelamento.value = {
            data: new Date(),
            motivo: "falha ao carregar motivo de cancelamento| ou nao existe",
          };
          loader.close();
        }
      }

      loader.close();
    });

    const setPreview = (profile) => {
      preview.value = profile;
    };

    const edit = async () => {
      const validate = isEmpty(venda.value);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        modal.open(
          "Atenção",
          "Deseja realmente editar essa venda?",
          "warning",
          "Sim",
          async () => {
            try {
              loader.open();
              await PUT(
                `vendas/${route.params.id}`,
                venda.value
              );
              alert.open(
                "Sucesso!",
                `A venda foi editada com sucesso!`,
                "success"
              );
              loader.close();
              router.push("/vendas");
            } catch (e) {
              loader.close();
              alert.open("Atenção!", e.message, "warning");
            }
          },
          modal.close()
        );
      }
    };

    const cancel = () => {
      modalconfirmation.open(route.params.id);
    };

    const trocarMotivo = () => {
      modalTrocaMotivo.open(motivosCancelamento.value.id);
    };

    const deleteItem = (param) => {
      loader.open();
      if (isCancelado.value) {
        alert.open("Atenção!", `Venda ja Cancelada!`, "warning");
        loader.close();
      } else {
        const deleteFunction = async function () {
          if (detalhes.value.length > 1) {
            const auxValorOriginal = parseFloat(
              venda.value["valor_original"].split("R$")[1].replace(",", ".")
            );
            const auxValorLoja = parseFloat(
              venda.value["valor_loja"].split("R$")[1].replace(",", ".")
            );

            const NewValorOriginal = auxValorOriginal - param.valor;

            const newValorLoja =
              (NewValorOriginal * auxValorLoja) / auxValorOriginal;

            const body = { ...venda.value };

            delete body.id;
            delete body.data2;
            delete body.data_aprovacao2;

            body["valor_loja"] = newValorLoja.toFixed(2);
            body["valor_original"] = NewValorOriginal.toFixed(2);

            venda.value["valor_loja"] = toMoney(newValorLoja);
            venda.value["valor_original"] = toMoney(NewValorOriginal);
            try {
              loader.open();

              await DELETE(`vendas_produto_qrcode/${param.id}`);

              await PUT(`vendas/${route.params.id}`, body);

              detalhes.value = detalhes.value.filter(
                (item) => item.id != param.id
              );
              loader.close();
              alert.open(
                "Sucesso!",
                `Produto deletado com sucesso!`,
                "success"
              );
            } catch (e) {
              loader.close();
              alert.open("Erro", e, "danger");
            }
          } else {
            //updade na tabela
            alert.open(
              "Atenção!",
              `Só há um produto relacionado a essa venda. Para cancelar, clique em Cancelar Venda Integral!`,
              "warning"
            );
          }
        };
        modal.open(
          "Atenção",
          "Deseja realmente excluir esse produto desta venda?",
          "warning",
          "Sim",
          () => deleteFunction(),
          modal.close()
        );

        loader.close();
      }
    };

    const visualizarQrcode = (param) => {

      QRCodes.value = [];
      const info = {
        nome: venda.value.nome,
        detalhes: param.detalhes,
        qrcode: param.qrcode,
      }
      QRCodes.value.push(info)
      showModal.value = true;
    };

    const trocarqrcode = async (param) => {
      modal.open(
        "Atenção",
        "Deseja realmente trocar esse QRCode?",
        "warning",
        "Sim",
        async () => {
          try {
            loader.open();
            await GET(`vendas_produto_qrcode/${param.id}/qrcode`);
            const aux = await GET(`/vendas/${route.params.id}/detalhes`);
            detalhes.value = aux.map((elem) => {
              elem.identifica = venda.value.pedido + "/" + elem.identificador;
              return elem;
            });
            if (venda.value.status_pedido == 0) {
              isCancelado.value = true;
            }
            loader.close();
            alert.open("Sucesso!", `QRCode trocado com sucesso!`, "success");
          } catch (e) {
            loader.close();
            alert.open("Erro", e, "danger");
          }
        },
        modal.close()
      );
    };

    const openModalEmail = () => {
      modalEmail.open(venda.value.meio_pagamento === 'Voucher'? 1 : 2);
    };

    const closeShow = (value) => {
      showModal.value = value
    }


    const reenviarFirebase = async () => {
      modal.open("Atenção",
        "Deseja reenviar essa venda para o firebase ? " +
        "",
        "warning",
        "Sim",
        async () => {
          try {
            loader.open();

            await POST_VENDAS(`venda/${route.params.id}/reenvio_firebase`)


            alert.open(
              "Sucesso!",
              `Reenvio da venda realizado com sucesso!`,
              "success"
            );
            loader.close();
          } catch (error) {
            alert.open("Atenção!", error, "danger");
            loader.close();
          }
        })
    }

    const headers = [
      { key: "Id", value: "id" },
      { key: "Detalhes", value: "detalhes" },
      { key: "Produto", value: "nome produto" },
      { key: "Valor", value: "valor" },
      { key: "SubTipo", value: "nome subproduto" },
      { key: "Identificador ", value: "identifica" },
      { key: "Options", value: "" },
    ];

    const options = [
      {
        icon: "qrcode",
        action: (param) => trocarqrcode(param),
        title: "Trocar QRCode",
      },
      {
        key: "Cancelamento",
        title: "Cancelar QRCode",
        icon: "trash-can",
        value: 1,
        trash: true,
        action: (param) => deleteItem(param),
      },
    ];

    return {
      edit,
      venda,
      reenviarFirebase,
      profiles,
      toDate,
      setPreview,
      preview,
      detalhes,
      subtipos,
      tipos,
      eventos,
      headers,
      options,
      cancel,
      store,
      isCancelado,
      motivosCancelamento,
      trocarMotivo,
      openModalEmail,
      QRCodes,
      showModal,
      closeShow
    };
  },
};
</script>

<style>
.cancelado {
  z-index: 50;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin-block-end: -20px;
  margin-top: -20px;
}
</style>